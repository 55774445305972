<template>
  <v-container fluid class="invite-container px-0 px-sm-auto">
    <CategoryTitle :category="category" />

    <!-- CODICE SCONTO -->
    <v-row
      no-gutters
      class="personal-code pt-4 pb-11 default--text"
      justify="center"
      align="center"
      v-if="code"
    >
      <v-col cols="12" class="text-center">
        <h3 class="code-title">
          {{ $t("invite.code.title") }}
        </h3>
        <v-row
          no-gutters
          class="text-h3 font-weight-bold code-box rounded-sm py-3 mt-4 mx-auto"
          align="center"
        >
          <v-col cols="2">&nbsp;</v-col>
          <v-col cols="8" class="text-h2">{{
            personalCode.tellFriend.code
          }}</v-col>
          <v-col cols="2">
            <v-icon large @click="copyCode">$copy</v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider />

    <!-- BUONI SCONTO -->
    <v-row no-gutters class="gift-certificates pt-7" v-if="giftCertificates">
      <v-col cols="12" class="text-center">
        <h3 class="gifts-title">
          {{ $t("invite.gifts.title") }}
        </h3>
        <v-row
          no-gutters
          class="count-box text-h3 font-weight-bold rounded-sm py-6 mt-4 mx-auto default--text"
          align="center"
        >
          <v-col cols="12">
            <div class="count font-weight-bold">
              {{ giftCertificates.length }}
            </div>
            <div class="text-h4">
              {{ $tc("invite.gifts.friends", giftCertificates.length) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="list my-11">
      <v-list dense class="w-100">
        <v-list-item v-for="(item, idx) in giftCertificates" :key="idx">
          <v-row justify="space-between" class="px-4">
            <div>{{ `${item.name} (${$n(item.amount, "currency")})` }}</div>
            <div>{{ formatEndDate(item.endDate) }}</div>
          </v-row>
        </v-list-item>
      </v-list>
    </v-row>

    <v-divider />

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
    />
  </v-container>
</template>
<style lang="scss">
.invite-container {
  .invite-card {
    .v-image {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .desc {
      div {
        line-height: 22px;
        @media #{map-get($display-breakpoints, 'xs-only')} {
          line-height: 18px;
        }
      }
      .ul-classes {
        margin: 20px 0px;
        .li-classes {
          list-style: disc !important;
          margin-left: 25px;
        }
      }
    }
  }
  .personal-code {
    .code-box {
      border: 2px solid var(--v-primary-lighten1);
      max-width: 330px;
      .v-image {
        cursor: pointer;
      }
    }
  }
  .gift-certificates {
    .count-box {
      border: 1px solid $border-color;
      max-width: 330px;
      .count {
        font-size: 41px;
      }
    }
  }

  .list {
    .v-list-item:nth-child(odd) {
      background: var(--v-primary-lighten2);
    }
  }

  .instructions {
    .instruction-box {
      border: 1px solid var(--v-grey-base);
      border-radius: $border-radius-root;
      padding: 24px;
      color: $primary;
      min-height: 240px;
      height: 100%;
      background-color: var(--v-primary-lighten2);
      @media #{map-get($display-breakpoints, 'sm-only')} {
        min-height: 330px;
      }
      .text-h4 {
        line-height: 28px;
        min-height: 75px;
      }
    }
  }

  .uses-clause {
    .li-classes {
      list-style: disc !important;
      margin-left: 25px;
    }
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import categoryMixins from "~/mixins/category";

import InviteService from "~/service/inviteService";

import get from "lodash/get";

export default {
  name: "Invite",
  components: { CategoryTitle },
  mixins: [categoryMixins],
  data() {
    return {
      personalCode: null,
      instructions: null,
      swiperOption: {
        spaceBetween: 10,
        autoplay: false,
        watchOverflow: true,
        pagination: {
          el: "#widget-slider-pagination-invite-steps",
          clickable: true
        },
        navigation: {
          prevEl: "#widget-slider-prev-invite-steps",
          nextEl: "#widget-slider-next-invite-steps"
        },
        breakpoints: {
          600: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 3
          }
        }
      }
    };
  },
  computed: {
    catDescr() {
      return get(this.category, "metaData.category_info.DESCRIPTION");
    },
    code() {
      return (
        this.personalCode &&
        this.personalCode.tellFriend &&
        this.personalCode.tellFriend.code
      );
    },
    giftCertificates() {
      // let array = [
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "01/05/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "05/05/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "07/06/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "15/09/2022"
      //   }
      // ];
      // if (this.personalCode && this.personalCode.userGiftCertificates) {
      //   array.concat(
      //     this.personalCode && this.personalCode.userGiftCertificates
      //   );
      // }
      // return array;
      if (this.personalCode && this.personalCode.userGiftCertificates) {
        return this.personalCode && this.personalCode.userGiftCertificates;
      }
      return [];
    },
    usesClauseList() {
      let list = this.geti18nObject("invite.usesClause.lists");
      return list;
    },
    cardList() {
      let list = this.geti18nObject("invite.card.lists");
      return list;
    }
  },
  methods: {
    copyCode() {
      navigator.clipboard.writeText(this.personalCode.tellFriend.code);
      global.EventBus.$emit("success", {
        message: global.EventBus.$t("invite.code.copied")
      });
    },
    loadInstructionSteps() {
      //TODO: gestire chiamata per popolare anche il footerbanner
      this.instructions = [
        {
          title: "Ottieni il codice sconto",
          description:
            "Ottieni il codice sconto nella sezione dedicata di questa pagina."
        },
        {
          title: "Condividilo con i tuoi amici",
          description:
            "Utilizza i canali che preferisci, come WhatsApp e Facebook, per inviare ai tuoi amici il codice sconto."
        },
        {
          title: "Ricevi il buono sconto a te dedicato",
          description:
            "Per ogni amico che effettua la sua prima spesa online ricevi una mail di notifica e uno sconto del 10% che potrai utilizzare per il tuo prossimo ordine."
        }
      ];
    },
    formatEndDate(date) {
      return this.$dayjs(date).format("D/MM/YYYY");
    }
  },
  async created() {
    await this.loadInstructionSteps();
    let response = await InviteService.getPersonalCode();
    if (response) {
      this.personalCode = response;
    }
  }
};
</script>
